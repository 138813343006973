import { Observable, of, timer, NEVER, BehaviorSubject } from 'rxjs';
import { switchMap, map, takeWhile, tap, startWith, finalize, distinctUntilChanged } from 'rxjs/operators';

export class Timer {
    // https://stackblitz.com/edit/rxjs-rajp6s
    private time: number;
    private countdown: number;
    public readonly seconds$: Observable<string>;
    private restart = new BehaviorSubject<boolean>(true);
    private finished: BehaviorSubject<boolean>;
    public readonly finished$: Observable<boolean>;

    constructor(countdown) {
        this.finished = new BehaviorSubject<boolean>(false);
        this.finished$ = this.finished.asObservable().pipe(distinctUntilChanged());
        this.countdown = countdown;
        this.reset();
        const INTERVAL = 1000;
        const K = 1000;
        let current: number;

        const toHoursDisplay = (ms: number) => Math.floor(ms / K / 60 / 60);
        const toMinutesDisplay = (ms: number) => Math.floor(((ms / K) % 3600) / 60);
        const toSecondsDisplay = (ms: number) => Math.floor(ms / K) % 60;

        function padNum(n) {
            return n < 10 ? `0${n}` : n.toString();
        }

        const toSecondsDisplayString = (ms: number) => {
            const hours = toHoursDisplay(ms);
            const minutes = toMinutesDisplay(ms);
            const seconds = toSecondsDisplay(ms);
            return `${hours}:${padNum(minutes)}:${padNum(seconds)}`;
        };

        const currentSeconds = () => this.time / INTERVAL;
        const toMs = (t: number) => t * INTERVAL;
        const toRemainingSeconds = (t: number) => currentSeconds() - t;

        const remainingSeconds$ = this.restart. // of(true).
            pipe(
                switchMap((running: boolean) => (running ? timer(0, INTERVAL) : NEVER)),
                map(toRemainingSeconds),
                takeWhile(t => t >= 0),
                tap({ complete: () => this.finished.next(true) })
            );

        const ms$ = remainingSeconds$.pipe(
            map(toMs),
            tap(t => current = t)
        );

        const seconds$ = ms$.pipe(
            map(toSecondsDisplayString),
            startWith(toSecondsDisplayString(this.time).toString())
        );

        this.seconds$ = seconds$;
    }

    reset() {
        const K = 1000;
        const MINUTES = this.countdown;
        const TIME = MINUTES * K * 60;
        this.time = TIME;
        this.restart.next(false);
        this.restart.next(true);
        this.finished.next(false);
    }

    add(minutes: number) {
        this.time = this.time + 60 * 1000 * minutes;
    }
}
