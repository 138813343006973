import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'mea-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent {
  @Input() name: string;
  @HostBinding('class') cssClass = 'logo';
}
