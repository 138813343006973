import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { UPDATE_ACTIVITY, UPDATE_SUBJECTS } from '../graphql.module';

@Component({
  selector: 'mea-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.css']
})
export class ProfessionComponent implements OnInit {

  static profession(hierarchy) {
    const profession = hierarchy.substr(0, 2);

    const PROFESSIONS = {
      '03': { name: 'מתמטיקה', hierarchy: '03' },
      '05': { name: 'אנגלית', hierarchy: '05' },
      '01': { name: 'לשון', hierarchy: '01' },
      '06': { name: 'היסטוריה', hierarchy: '06' },
      '04': { name: 'תנך', hierarchy: '04' },
      '02': { name: 'אזרחות', hierarchy: '02' },
    };

    return PROFESSIONS[profession];
  }

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo.mutate({
      mutation: UPDATE_SUBJECTS,
      variables: {
        input: [
          { name: '', hierarchy: '', __typename: 'PartialSubjectInput' },
          { name: '', hierarchy: '', __typename: 'PartialSubjectInput' },
          { name: 'בחרו מקצוע', hierarchy: '', __typename: 'PartialSubjectInput' },
        ]
      }
    }).subscribe();

    this.apollo.mutate({
      mutation: UPDATE_ACTIVITY,
      variables: {
        input: '_'
      }
    }).subscribe();
  }

}
