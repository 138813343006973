import { ListViewComponent } from '@syncfusion/ej2-angular-lists';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import * as subjectTree from 'src/assets/subjects.json';
import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { UIRouter } from '@uirouter/angular';
import { UPDATE_ACTIVITY, UPDATE_ACTIVITY_SUBJECTS, UPDATE_SUBJECTS } from '../graphql.module';

function sortSubjects(hierarchy: {child: any[]}) {
  if (!hierarchy.child) {
    return hierarchy;
  }
  const newHierarchy = hierarchy;
  newHierarchy.child = hierarchy.child.map(sortSubjects);
  newHierarchy.child.sort((a, b) => a.id.localeCompare(b.id));
  return newHierarchy;
}

@Component({
  selector: 'mea-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css']
})
export class TopicComponent implements OnInit, OnDestroy {
  private activity2hebrew = {
    practice: 'תרגול',
    test: 'בוחן',
    finalTest: 'מבחן',
    million: 'לגעת במיליון',
    '1vs1': 'אחד על אחד'
  };
  @Input() activity: 'practice' | 'test' | 'finalTest' | 'million' | '1vs1';
  @Input() profession: { name: string, hierarchy: string };
  @Input() deeplink?: string;

  private times = 0;
  isMultiple = false;
  listview_: ListViewComponent;

  @ViewChild(ListViewComponent, { static: false }) set listview(listview: ListViewComponent) {
    this.listview_ = listview;
    if (this.deeplink && listview) {
      this.times++;
    }
    if (this.times === 2) {
      const parts = this.deeplink.split('-');
      let path = parts.shift();
      for (const part of parts) {
        of(0).pipe(delay(0), map(() => {
          path = `${path}-${part}`;
          const name = this.nameForHierarchy(path);
          listview.selectItem({ id: path, text: name });
          this.onSelect({ data: { id: path, text: name } });
        })).subscribe();
      }
    }
  }

  backup: any[];
  data: any;

  private title1;
  private title2;
  private title3;
  private emptyTitle = { name: '', hierarchy: '', __typename: 'PartialSubjectInput' };
  private path = [];
  private isMath: boolean;

  constructor(private apollo: Apollo, private router: UIRouter) {
    this.data = (subjectTree as any).default;
    this.backup = this.data;
    this.backup = this.backup.map(sortSubjects);
  }

  nameForHierarchy(hierarchy: string): string {
    return this.nameForHierarchyRecursive(hierarchy, this.backup, 0);
  }

  nameForHierarchyRecursive(hierarchy: string, subtree: any[], depth: number) {
    const parts = hierarchy.split('-');
    const lookup = parts.slice(0, depth + 1).join('-');

    const target = subtree.find(topic => topic.id === lookup);

    if (target.id === hierarchy) {
      return target.text;
    }

    return this.nameForHierarchyRecursive(hierarchy, target.child, depth + 1);
  }

  init() {
    const MATH_HIERARCHY = '03';
    this.isMath = this.profession.hierarchy.startsWith(MATH_HIERARCHY);
    this.data = this.backup.find(topic => topic.id === this.profession.hierarchy).child;

    let choice;
    switch (this.activity) {
      // subtopic
      case 'practice':
      case 'test':
        choice = 'בחרו תת נושא עבור ה' + this.activity2hebrew[this.activity];
        break;
      // topic
      case 'million':
      case '1vs1':
        choice = 'בחרו נושא עבור ' + this.activity2hebrew[this.activity];
      case 'finalTest':
        choice = 'בחרו נושא עבור ה' + this.activity2hebrew[this.activity];
        this.data = this.data.map(child => this.removeSubtopics(child));
        break;
      default: // should be exhaustive
        throw Error('unknown activity');
    }

    this.title1 = { name: this.profession.name, hierarchy: this.profession.hierarchy, __typename: 'PartialSubjectInput' };
    this.title2 = this.emptyTitle;
    this.title3 = { ...this.emptyTitle, name: choice };

    this.setTitles();
    this.path = [this.title1];
  }

  ngOnInit() {
    this.apollo.mutate({
      mutation: UPDATE_ACTIVITY_SUBJECTS,
      variables: {
        input: this.activity
      }
    }).subscribe();
    this.init();
  }

  ngOnDestroy(): void {
    /*this.apollo.mutate({
      mutation: UPDATE_ACTIVITY,
      variables: {
        input: '_'
      }
    }).subscribe();*/
  }

  removeSubtopics(tree) {
    if (tree.child) {
      const newTree = { ...tree };
      if (tree.child[0].icon === 'file') {
        delete newTree.child;
      } else {
        newTree.child = newTree.child.map(child => this.removeSubtopics(child));
      }
      return newTree;
    }
    return tree;
  }

  onSelect(event) {
    const item: { text: string, id: string } = event.data;
    const topic = { name: item.text, hierarchy: item.id };
    const depth = topic.hierarchy.split('-').length;
    if (this.isMath &&
      (this.activity === 'practice' || this.activity === 'test')) {
      switch (depth) {
        case 2:
          this.title1 = { ...this.emptyTitle, ...topic };
          this.title2 = this.emptyTitle;
          this.path = [this.path[0], this.title1];
          break;
        case 3:
          this.title2 = { ...this.emptyTitle, ...topic };
          this.path[2] = this.title2;
          break;
      }
    } else if (depth === 2) {
      this.path = [this.path[0]];
      if (this.isMath) {
        this.title2 = { ...this.emptyTitle, ...topic };
        this.path.push(this.title2);
      }
    }
    this.setTitles();
  }

  setTitles() {
    this.apollo.mutate({
      mutation: UPDATE_SUBJECTS,
      variables: { input: [this.title1, this.title2, this.title3] }
    }).subscribe();
  }

  onMultiple(event: { checked: boolean }) {
    this.isMultiple = event.checked;
  }

  buildTest() {
    const items: any = this.listview_.getSelectedItems();
    let extractId;
    if (this.isMath) {
      extractId = item => item.data.id;
    } else {
      extractId = item => item.id;
    }
    const topics = items.data.map(extractId);
    const path = [this.title1];
    if (this.isMath) {
      path.push(this.title2);
    }
    this.router.stateService.go('practice', { activity: this.activity, path, topics});
  }

}
