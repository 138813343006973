import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { UIRouter } from '@uirouter/angular';
import gql from 'graphql-tag';
import { pluck, map } from 'rxjs/operators';

const REGISTER = gql`
  mutation RegisterUser(
    $email: String!,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String!,
    $extraPhoneNumber: String,
    $school: String!,
    $city: String!,
    $address: String!,
    $password: String!,
    $contractId: Int!,
    $code: String!) {

    registerUser(input: {
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      email: $email,
      password: $password,
      schoolName: $school,
      address: $address,
      city: $city,
      extraPhoneNumber: $extraPhoneNumber,
      contractId: $contractId,
      code: $code
    }) {
      user {
        id
        firstName
        lastName
        createdAt
      }
    }

  }
`;

const IS_EMAIL_EXISTS = gql`
  query IsEmailExists($email: String!) {
    isEmailExists(email: $email)
  }
`;

interface RegisterForm {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  extraPhoneNumber?: string;
  school: string;
  city: string;
  address: string;
  code: string;
  contractId: number;
}


// https://coryrylan.com/blog/angular-form-builder-and-validation-management
@Component({
  selector: 'mea-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  registerForm: FormGroup;
  working: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private router: UIRouter) {
    this.working = false;
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email], this.validateEmailNotTaken.bind(this)],
      password: ['', Validators.required],
      code: ['', [Validators.required, Validators.pattern('[0-9]{6}')]],
      contractId: ['', [Validators.required, Validators.pattern('[0-9]+')]],

      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9\-]+[0-9]')]],
      extraPhoneNumber: ['', Validators.pattern('[0-9\-]+[0-9]')],
      school: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
    });
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.data.type && event.data.type === 'MeaPayment') {
      console.log(event);
      debugger;
    }
  }

  ngOnInit() {
  }

  onSubmit(details: RegisterForm) {
    this.working = true;
    this.registerForm.get('code').setErrors(null);
    details.contractId = +details.contractId;

    this.apollo.mutate<{ user: { id: number, firstName: string, lastName: string, createdAt: string } }>({
      mutation: REGISTER,
      variables: details
    }).subscribe(({ data }) => {
      this.working = false;
      this.registerForm.reset();
      this.router.stateService.go('login');
    }, (error) => {
      this.working = false;
      for (const problem of error.graphQLErrors) {
        if (problem.message.startsWith('security code')) {
          this.registerForm.get('code').setErrors({ invalidCode: true });
        }
      }
    });
  }

  getErrorMessage(field) {
    const control = this.registerForm.get(field);
    return control.hasError('required') ? 'שדה חובה' :
      control.hasError('email') ? 'כתובת אימייל לא חוקית' :
        control.hasError('emailTaken') ? 'כתובת האימייל קיימת כבר במערכת' :
          control.hasError('pattern') ? 'יש להשתמש בספרות בלבד' :
            control.hasError('invalidCode') ? 'קוד הרשמה לא פעיל' :
              '';
  }

  validateEmailNotTaken(control: AbstractControl) {
    // TODO add debounce time and disctintUntilChanged
    return this.apollo.query<{ isEmailExists: boolean }, { email: string }>({
      query: IS_EMAIL_EXISTS,
      variables: { email: control.value }
    }).pipe(pluck('data', 'isEmailExists'), map(exists => {
      return exists ? { emailTaken: true } : null;
    }));
  }
}
