import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  private currentIndex: BehaviorSubject<number>;
  private slider: any;
  private active: BehaviorSubject<boolean>;
  private review: BehaviorSubject<boolean>;
  private total: number;
  public readonly current: Observable<number>;
  public readonly atStart: Observable<boolean>;
  public readonly atEnd: Observable<boolean>;
  public readonly isActive: Observable<boolean>;
  public readonly inReview: Observable<boolean>;

  constructor() {
    this.currentIndex = new BehaviorSubject(0);
    this.current = this.currentIndex.asObservable();
    this.atStart = this.current.pipe(map(current => (current === 0)));
    this.atEnd = this.current.pipe(map(current => (current === (this.total - 1))));
    this.active = new BehaviorSubject(false);
    // https://dev.to/cooperdev/when-using-settimeout-is-not-your-best-option-3p4b
    // ExpressionChangedAfterItHasBeenCheckedError fix
    this.isActive = this.active.asObservable().pipe(delay(0));

    this.review = new BehaviorSubject(false);
    this.inReview = this.review.asObservable().pipe(delay(0));
  }

  register(slider, total) {
    this.slider = slider;
    this.total = total;
    this.currentIndex.next(0);
    this.active.next(true);
    this.review.next(false);
  }

  reset(total) {
    this.total = total;
    this.currentIndex.next(0);
    this.active.next(true);
    this.review.next(false);
  }

  unregister() {
    this.total = 0;
    this.slider = undefined;
    this.active.next(false);
    this.review.next(false);
  }

  private goTo(next) {
    let el = this.slider;
    if (!el) {
      debugger;
      return; // no-op
    }

    // el.scrollLeft = (-el.clientWidth) * next;
    el.scroll({left: (-el.clientWidth) * next, behavior: 'smooth'});
    this.currentIndex.next(next);
  }

  nextSlide() {
    this.goTo(Math.min(this.currentIndex.getValue() + 1, this.total - 1));
  }

  prevSlide() {
    this.goTo(Math.max(this.currentIndex.getValue() - 1, 0));
  }

  goToReview() {
    this.review.next(true);
    this.goTo(0);
  }

  onlyReview() {
    this.review.next(true);
  }
}
