import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mea-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.css']
})
export class AnswerComponent implements OnInit {
  private static stateClasses = {
    LOADING: 'loading-item',
    PENDING: '',
    SELECTED: 'board__answer--selected',
    CORRECT: 'board__answer--correct',
    WRONG: 'board__answer--wrong'
  };
  public state: 'LOADING' | 'PENDING' | 'SELECTED' | 'CORRECT' | 'WRONG' = 'LOADING';
  get stateClass() {
    return AnswerComponent.stateClasses[this.state];
  }

  @Input() id: number;
  @Input() kind: 'IMAGE' | 'TEXT';
  @Input() answer: string;
  @Input() correct: boolean;
  @Input() question: number;
  @Input() inTest: boolean;
  private inReview_: boolean;

  get inReview(): boolean {
    return this.inReview_;
  }
  @Input() set inReview(inReview: boolean) {
    this.inReview_ = inReview;
    if (this.inReview) {
      if (this.correct) {
        this.state = 'CORRECT';
      } else if (this.state === 'SELECTED') { // wrong and checked
        this.state = 'WRONG';
      }
    }
  }
  @Input() checked: boolean;

  @Output() selected: EventEmitter<any> = new EventEmitter();


  ngOnInit() {
    this.state = 'PENDING';
    if (this.checked) {
      this.state = 'SELECTED';
    }
    if (this.inReview_) {
      if (this.correct) {
        this.state = 'CORRECT';
      } else if (this.checked) { // wrong and checked
        this.state = 'WRONG';
      }
    }
  }

  onSelected() {
    if (this.state === 'LOADING') { return; }

    this.state = 'SELECTED';
    if (!this.inTest) {
      this.state = (this.correct) ? 'CORRECT' : 'WRONG';
    }
    this.selected.emit(
      { id: this.id, state: this.state }
    );
  }
}
