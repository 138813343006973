import { Component, Input, HostBinding, OnInit } from '@angular/core';
import { Question } from './question.model';

const DEFAULT_STARS = 3;

@Component({
  selector: 'mea-question-section',
  templateUrl: './question-section.component.html',
  styleUrls: ['./question-section.component.css']
})
export class QuestionSectionComponent implements OnInit {
  @HostBinding('class') cssClasses = 'board__section board__question';
  @Input() kind: 'image' | 'text';
  @Input() hierarchy: string;
  @Input() question: string;
  @Input() difficulty: number;

  ngOnInit() {
    // millioner difficulty = 4
    this.difficulty = Math.max(1, Math.min(3, this.difficulty));
  }

  times(n: number) {
    if (!Number.isInteger(n)) { n = DEFAULT_STARS; }
    return new Array(n);
  }
}
