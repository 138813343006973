import { Component, Input, HostBinding } from '@angular/core';
import { MenuItem } from './menu-item.model';

@Component({
  selector: 'mea-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent {
  @Input() item: MenuItem;
  @Input() activity: '_' | 'practice' | 'test' | 'finalTest' | 'million' | '1vs1';

  @HostBinding('class') class = 'board__item js_link';
  @HostBinding('class.board__item--ingame') get ingame() {
    return (this.activity === 'million' || this.activity === '1vs1');
  }
  @HostBinding('class.board__item--disabled') get disabled() {
    return this.item.disabled;
  }
}
