import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mea-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  title: string;
  html?: string;
  pdf?: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title;
    this.html = data.html;
    this.pdf = data.pdf;
  }

  ngOnInit() {
  }

}
