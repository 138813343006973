import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, NgForm, Validators, FormGroup } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { UIRouter } from '@uirouter/angular';
import gql from 'graphql-tag';

const LOG_IN = gql`
  mutation LogIn($email: String!, $password: String!) {
    authenticate(input: {email: $email, password: $password}) {
      jwt
    }
  }
`;

@Component({
  selector: 'mea-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  public badCredentials: boolean;
  working: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    // private userService: UserService,
    private router: UIRouter) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.badCredentials = false;
  }

  ngOnInit() {
  }

  onSubmit({ email, password }: { email: string, password: string }) {
    this.badCredentials = false;
    this.working = true;
    this.apollo.mutate<{ authenticate: { jwt: string } }>({
      mutation: LOG_IN,
      variables: {
        email,
        password
      }
    }).subscribe(({ data }) => {
      const token = data.authenticate.jwt;
      if (token) {
        localStorage.setItem('token', token);
        // this.userService.email = email;
        this.loginForm.reset();
        this.router.stateService.go('home');
      } else {
        this.badCredentials = true;
        this.working = false;
      }
    }, (error) => {
      // server down?
      debugger;
    });
  }

  getErrorMessage(field) {
    const control = this.loginForm.get(field);
    return control.hasError('required') ? 'שדה חובה' :
      control.hasError('email') ? 'כתובת אימייל לא חוקית' :
        '';
  }

}
