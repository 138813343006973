import { Component, OnInit, HostBinding, Input, OnDestroy, OnChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpComponent } from 'src/app/help/help.component';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, Observable, of, timer, NEVER, combineLatest } from 'rxjs';
import { SliderService } from 'src/app/slider.service';
import { pluck, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { UI } from 'src/app/graphql.module';
import { MillionService } from 'src/app/million.service';
import { Timer } from './timer';
import { UIRouter } from '@uirouter/angular';

export interface ProblemData {
  problem?: Problem;
  problems?: Problem[];
}

export interface QuizData {
  data: Quiz;
}

export interface Quiz {
  subjectsList: Subject[];
}

export interface Subject {
  name: string;
  hierarchy: string;
  parent?: Subject;
  problemsList: Problem[];
}

export interface Problem {
  article?: string;
  id: string;
  difficulty: Difficulty;
  question: string;
  correct: string;
  wrong2: string;
  wrong3: string;
  wrong4: string;
  clue: string;
  solution: string;
  subject?: Subject;
  __typename?: string;
}

export interface Difficulty {
  id: number;
}

export interface OldProblem {
  id: string;
  kind: 'IMAGE' | 'TEXT';
  difficulty: number;
  question: string;
  correct: 0 | 1 | 2 | 3;
  answers: string[];
  solution: string;
  clue: string;
  __typename: 'Problem';
}

export interface MenuItem {
  disabled?: boolean;
  icon: string;
  label: Observable<string>;
  eventName: string;
}

// TS enum https://stackoverflow.com/questions/41179474/use-object-in-typescript-enum
export const MenuItem = {
  CLUE: {
    eventName: 'clue',
    icon: 'search',
    label: of('רמז'),
  },
  SOLUTION: {
    eventName: 'solution',
    icon: 'assignment',
    label: of('פתרון'),
  },
  FORMULAS: {
    eventName: 'formulas',
    icon: 'functions',
    label: of('נוסחאות'),
  },
  TEXT: {
    eventName: 'text',
    icon: 'subject',
    label: of('טקסט'),
  },
  PRINT: {
    eventName: 'print',
    label: of('הדפס'),
    icon: 'print',
  },
  MORE_TIME: {
    eventName: 'time',
    icon: 'alarm_add',
    label: of('תוספת זמן'),
  },
  CHANGE: {
    eventName: 'change',
    icon: 'autorenew',
    label: of('החלף שאלה'),
  },
  HALF: {
    eventName: 'half',
    icon: 'vertical_align_center',
    label: of('50:50'),
  }, /*
  TIMER_TEST: {
    disabled: true,
    eventName: 'timer',
    icon: 'assignment',
    label: TimerFactory(45),
  },
  TIMER_FINAL_TEST: {
    disabled: true,
    eventName: 'timer',
    icon: 'assignment',
    label: TimerFactory(90),
  }*/
  LOGOUT: {
    eventName: 'logout',
    icon: 'cancel',
    label: of('יציאה')
  }
} as const;

// export type MenuItem = typeof MenuItem;

export const MenuItems = {
  // BASIC: [MenuItem.PRINT, MenuItem.CLUE, MenuItem.SOLUTION],
  PRACTICE_MATH: [MenuItem.FORMULAS, MenuItem.PRINT, MenuItem.CLUE, MenuItem.SOLUTION],
  EXAM_MATH: [MenuItem.FORMULAS, MenuItem.PRINT, MenuItem.CLUE], // MenuItem.TIMER],
  REVIEW_MATH: [MenuItem.FORMULAS, MenuItem.PRINT, MenuItem.SOLUTION], // MenuItem.GRADE],

  PRACTICE_REGULAR: [MenuItem.TEXT, MenuItem.PRINT, MenuItem.CLUE, MenuItem.SOLUTION],
  EXAM_REGULAR: [MenuItem.TEXT, MenuItem.PRINT, MenuItem.CLUE], // MenuItem.TIMER],
  REVIEW_REGULAR: [MenuItem.TEXT, MenuItem.PRINT, MenuItem.SOLUTION], // MenuItem.GRADE],

  MILLION: [MenuItem.MORE_TIME, MenuItem.CHANGE, MenuItem.CLUE, MenuItem.HALF],
  IDLE: [MenuItem.LOGOUT]
} as const;

type millionButtons = 'time' | 'clue' | 'half' | 'change';

@Component({
  selector: 'mea-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy, OnChanges {
  private _subscriptions: Subscription[] = [];
  isActive$: Observable<boolean>;
  loading = true;
  error: any;
  problem: Problem;
  subjectList: { name: string, hierarchy: string }[];
  items: readonly any[];
  score = 100;
  usedInMillion: { [key in millionButtons]?: boolean } = {};
  ui: Partial<UI>;

  @Input() inReview: boolean;
  @Input() activity: '_' | 'practice' | 'test' | 'finalTest' | 'million' | '1vs1';
  @Input() type: 'math' | 'text';

  @HostBinding('class') class = 'board__menu';
  time: number;

  @HostBinding('class.board__menu--ingame') get ingame() {
    return (this.activity === 'million' || this.activity === '1vs1');
  }

  _items(score?: number): readonly MenuItem[] {
    if (!this.activity || this.activity === '_') { return MenuItems.IDLE; }
    if (this.activity === '1vs1') { return []; }
    if (this.activity === 'million') {
      return MenuItems.MILLION.map(button => {
        const result: MenuItem = { ...button };
        if (button.eventName in this.usedInMillion) {
          result.disabled = true;
        }
        return result;
      });
    }

    // practice
    const IS_MATH = this.type === 'math';

    if (this.activity === 'practice') {
      return IS_MATH ? MenuItems.PRACTICE_MATH : MenuItems.PRACTICE_REGULAR;
    } else if (this.activity === 'test' || this.activity === 'finalTest') {
      if (!this.inReview) {
        const countdownTime = (this.activity === 'test') ? 45 : 90;
        const countdown = new Timer(countdownTime);
        const item = {
          disabled: true,
          eventName: 'timer',
          icon: 'assignment',
          label: countdown.seconds$,
        };
        return [...(IS_MATH ? MenuItems.EXAM_MATH : MenuItems.EXAM_REGULAR), item];
      }

      score = score || 0; // no undefined
      const GRADE = {
        disabled: true,
        eventName: 'TIMER',
        icon: 'assignment',
        label: of('ציון: ' + score)
      };

      return [...(IS_MATH ? MenuItems.REVIEW_MATH : MenuItems.REVIEW_REGULAR), GRADE];
    }

    return [];
  }


  constructor(
    private dialog: MatDialog, private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private sliderService: SliderService,
    private millionareService: MillionService,
    private router: UIRouter) {
    this.isActive$ = this.sliderService.isActive;
  }

  ngOnChanges(...args: any[]) {
    this.ngOnInit();
  }

  ngOnInit() {
    this.usedInMillion = {};
    this.items = this._items();

    const graphqlMenuInputs = this.apollo.watchQuery<any>({
      query: gql`
        query MenuInputs {
          ui @client {
            score
          }
        }
      `
    }).valueChanges.pipe(pluck('data', 'ui')).pipe(distinctUntilChanged());

    this.millionareService.level$.pipe(withLatestFrom(graphqlMenuInputs))
      // combineLatest([this.millionareService.level$, graphqlMenuInputs, this.isActive$])
      .subscribe(([level, ui]: [number, UI]) => {
        if (level === 1) {
          this.usedInMillion = {};
          this.ui = ui;
          this.items = this._items(this.ui.score);
        }
      });

    // graphqlMenuInputs.pipe(withLatestFrom(this.sliderService.inReview)).
    combineLatest([graphqlMenuInputs, this.isActive$]).
      subscribe(([ui, isActive]: [UI, boolean]) => {
        if (!ui) {
          return;
        }

        const K = 1000;
        const INTERVAL = K;
        const MINUTES = (this.activity === 'test') ? 45 : 90;
        const TIME = MINUTES * K * 60;
        this.time = TIME;

        this.score = ui.score;
        this.ui = ui;
        if (this.inReview) {
          this.items = this._items(this.ui.score);
        }
      });

    this._subscriptions.push(this.apollo.watchQuery<any>({
      query: gql`
        query CurrentProblemId {
          ui @client {
            score
            activity
            currentProblemId
            subjectList {
              name
              hierarchy
            }
          }
        }
      `
    }).valueChanges.subscribe(result => {
      if (result.stale || !result.data.ui.currentProblemId) {
        return;
      }

      // this.activity = result.data.ui.activity;
      // this.score = result.data.ui.score;
      // this.items = this._items(result.data.ui.subjectList[0].hierarchy, this.score);

      const currentProblemId = result.data.ui.currentProblemId;
      this.apollo.query<ProblemData>({
        query: gql`
            query ClueAndSolutionForCurrentProblem($id: Int!) {
              problem(id: $id) {
                id
                clue
                solution
                article
                subject {
                  hierarchy
                }
              }
            }
          `, variables: { id: currentProblemId }, fetchPolicy: 'cache-first'
      }).subscribe(problemResult => {
        this.problem = problemResult.data && problemResult.data.problem;
        this.loading = problemResult.loading;
        this.error = problemResult.errors;
      });
    }));
  }

  ngOnDestroy() {
    for (let subscription of this._subscriptions) {
      subscription.unsubscribe();
    }
  }

  clicked(eventName: string) {
    if (eventName === 'print') {
      window.print();
      return;
    }

    if (this.activity === 'million') {
      this.usedInMillion[eventName] = true;
      this.items = this._items();
    }

    const label = this.items.find(item => item.eventName === eventName).label;

    const dialogConfig = new MatDialogConfig();

    let hierarchy = '';
    if (this.items !== MenuItems.IDLE) {
      hierarchy = this.problem.subject.hierarchy.substr(0, '05-01'.length);
      dialogConfig.direction = (hierarchy.startsWith('05')) ? 'ltr' : 'rtl'; // isEnglish
      dialogConfig.maxHeight = '90%';
      dialogConfig.data = {
        title: label,
      };
    }

    if (eventName === 'clue' || eventName === 'solution') {
      dialogConfig.data.html = this.problem[eventName];
    } else if (eventName === 'text') {
      dialogConfig.data.html = `<p>${this.problem.article}</p>`;
    } else if (eventName === 'formulas') {
      let formulas = '';
      switch (hierarchy) {
        case '03-05': // 801
        case '03-06': // 802
        case '03-07': // 803
          formulas = '3'; break;
        case '03-08': // 804
        case '03-09': // 805
          formulas = '4'; break;
        case '03-10': // 806
        case '03-11': // 807
          formulas = '5'; break;
        default:
          break;
      }

      if (formulas !== '') {
        dialogConfig.data.pdf = this.sanitizer.bypassSecurityTrustResourceUrl(
          `../assets/formulas/${formulas}.pdf#view=FitH`
        );
      } else {
        dialogConfig.data.html = 'אין דף נוסחאות למקצוע זה';
      }
    } else if (eventName === MenuItem.HALF.eventName) {
      this.millionareService.hideHalf();
      return;
    } else if (eventName === MenuItem.MORE_TIME.eventName) {
      this.millionareService.addTime();
      return;
    } else if (eventName === MenuItem.CHANGE.eventName) {
      this.millionareService.changeQuestion();
      return;
    } else if (eventName === MenuItem.LOGOUT.eventName) {
      localStorage.removeItem('token');
      this.router.stateService.go('login');
      return;
    }

    this.dialog.open(HelpComponent, dialogConfig);
  }
}
