import { Component, OnInit, HostBinding, Input, Output, EventEmitter, OnDestroy, ViewChild, AfterViewInit, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { Observable, of, Subscription, Subject } from 'rxjs';
import { map, shareReplay, pluck, flatMap, tap, bufferCount, delay } from 'rxjs/operators';
import { zip } from 'rxjs';
import gql from 'graphql-tag';
import { Apollo, QueryRef } from 'apollo-angular';
import { ProblemData, OldProblem, QuizData, Quiz, Problem } from '../header/menu/menu.component';
import { Subject as SubjectModel } from '../header/subjects/subject/subject.model';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { SliderService } from '../slider.service';
import { ApolloQueryResult } from 'apollo-client';
import { MillionService } from '../million.service';
import { UPDATE_ACTIVITY, UPDATE_SUBJECTS, UPDATE_TYPE } from '../graphql.module';

const UPDATE_SCORE = gql`
  mutation ChangeScore($input: number)  {
    changeScore(score: $input) @client
  }
`;

@Component({
  selector: 'mea-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush // millionare-answer 5050 bug
})
export class PlaygroundComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Output() score: EventEmitter<number> = new EventEmitter<number>();
  @Input() game?: '1vs1' | 'million' | undefined;
  @Input() type: 'math' | 'text';
  @Input() activity: 'practice' | 'test' | 'finalTest' | 'million' | '1vs1';
  @Input() topic: { name: string, hierarchy: string };
  @Input() path: { name: string, hierarchy: string, __typename: string }[] = [];
  @Input() topics?: { name: string, hierarchy: string }[];
  scoreSubscription: Subscription;
  // @HostBinding('class') private class = 'playground';

  /*
  @HostBinding('class.playground--ingame') private get ingameCss() {
    return this.game !== undefined;
  }
  */

  private querySubscription: Subscription;
  loading$: Observable<boolean>;
  error$: Observable<readonly any[]>;
  quiz$: Observable<any>;

  public problems: Problem[];
  public problems$: Observable<Problem[]>;
  subjects$: Observable<SubjectModel[]>;
  current = 0;
  length = 1;
  inReview = false;
  rightAnswers = new Subject<boolean>();
  // public score: Observable<number>;

  public config: SwiperConfigInterface = {
    allowTouchMove: false,
    shortSwipes: false,
    longSwipes: false,
    direction: 'horizontal',
    slidesPerView: 1,
    navigation: true,
    pagination: true
  };

  // TODO update menu with current problem
  @ViewChild('ourslider', { static: false }) set ourslider(ourslider: ElementRef) {
    if (ourslider && ourslider.nativeElement) {
      this.sliderService.register(ourslider.nativeElement, this.length);
    }
  }

  @ViewChild(SwiperDirective, { static: false }) set slider(slider: SwiperDirective) {
    if (slider) {
      //slider.config.allowTouchMove = false;
      // Solves expression changes during initialization
      this.sliderService.register(slider, this.length);
    }
  }
  constructor(private apollo: Apollo, public sliderService: SliderService, private millionService: MillionService) { }

  ngAfterViewInit() {
    /*setTimeout(() => {
      debugger;
      let el = this.ourslider.nativeElement;
      el.scrollLeft = el.scrollLeft - el.clientWidth;
    }, 2500);*/
    /*this.quiz$.subscribe(_problems => {
      if (_problems) {
        this.sliderService.register(this._slider, this.length);
      }
    });*/
  }
  ngOnInit() {
    if (!this.topic && !this.topics) { return; }

    this.apollo.mutate({
      mutation: UPDATE_ACTIVITY,
      variables: {
        input: this.activity
      }
    }).subscribe();

    this.apollo.mutate({
      mutation: UPDATE_TYPE,
      variables: {
        input: this.type
      }
    }).subscribe();

    this.config.allowTouchMove = (this.activity !== 'million');

    this.path = this.path || [];
    const MATH_HIERARCHY = '03';
    let hierarchy;
    if (this.topic) {
      hierarchy = this.topic.hierarchy;
      this.path.push({ ...this.topic, __typename: 'PartialSubjectInput' });
    } else if (this.topics) {
      hierarchy = this.topics[0];
      this.path.push({ name: 'מגוון נושאים', hierarchy, __typename: 'PartialSubjectInput' });
    }
    this.type = (hierarchy.startsWith(MATH_HIERARCHY)) ? 'math' : 'text';
    if (this.path.length > 3) {
      this.path.shift();
    } else if (this.path.length < 3) {
      this.path.push({ name: '', hierarchy: '', __typename: 'PartialSubjectInput' });
    }
    this.apollo.mutate({
      mutation: UPDATE_SUBJECTS,
      variables: { input: this.path.map(t => ({ ...t, __typename: 'PartialSubjectInput' })) }
    }).subscribe();

    const source$ = this.getQuiz();
    this.loading$ = source$.pipe(pluck('loading'));
    this.error$ = source$.pipe(pluck('errors'));
    this.quiz$ = source$.pipe(pluck('data', 'problemsbyidsList'));

    /*this.querySubscription = this.quiz$.subscribe(_problems => {
      this.problems = _problems;
      if (this.problems) {
        this.length = this.problems.length;
        this.currentProblemChanged(0);
      }
    });*/
    this.problems$ = this.quiz$.pipe(tap(_problems => {
      this.problems = _problems;
      if (this.problems) {
        this.length = this.problems.length;
        // this.currentProblemChanged(0); // for clue-solution

        this.sliderService.reset(this.length);
        this.sliderService.current.subscribe({next: (currentId) => {
          this.currentProblemChanged(currentId);
        }});

        this.scoreSubscription = this.rightAnswers.pipe(bufferCount(_problems.length)).pipe(map((rightAnswers) => {
          const totalCount = rightAnswers.length;
          const correctCount = rightAnswers.filter(correct => correct).length;
          const score = Math.round(100 * correctCount / totalCount);

          this.apollo.mutate({
            mutation: UPDATE_SCORE,
            variables: { input: score }
          }).subscribe();

          // this.score.emit();
        })).subscribe();

      }
    })); // .pipe(map<Subject[], Problem[]>(_problems => _problems));
    /*this.subjects$ = this.quiz$.pipe(map<Subject[], SubjectModel[]>(subjectList => {
      let result: SubjectModel[] = [];
      let subject = subjectList[0];
      result.push({ name: subject.name, hierarchy: subject.hierarchy });
      subject = subject.parent;
      if (subject) {
        result.push({ name: subject.name, hierarchy: subject.hierarchy });
        subject = subject.parent;
        if (subject) {
          result.push({ name: subject.name, hierarchy: subject.hierarchy });
        }
      }
      result = result.reverse();
      return result;
    }));*/
  }

  getQuiz() {
    let hierarchies: any[];
    if (this.topic) {
      hierarchies = [this.topic.hierarchy];
    } else if (this.topics) {
      hierarchies = this.topics;
    }

    const hierarchyToIds = (h: string) => {
      return this.apollo.query<any>({
        query: gql`
          query ProblemIdsUnderCategory($hierarchy: String!) {
            subjectsList (condition: {hierarchy: $hierarchy}) {
              id
              name
              childProblemsList {
                id
              }
            }
          }
        `,
        variables: {
          hierarchy: h
        }
      });
    };

    const extractIds = map<ApolloQueryResult<any>, any[]>(result => {
      const ids = result.data.subjectsList[0].childProblemsList;
      return ids;
    });


    function shuffle<T>(a: T[]) {
      let j: number; let x: T; let i: number;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    }

    return zip(...hierarchies.map(h => hierarchyToIds(h).pipe(extractIds)))
      .pipe(map((arraiesOfIds: any[]) => {
        return arraiesOfIds.map(shuffle);
      }))
      .pipe(map((arraiesOfIds: any[][]) => {
        let howMany: number;
        switch (this.activity) {
          case 'test': howMany = 3; break;
          case 'finalTest': howMany = 5; break;
          case 'million': howMany = (14 + 1); break; // million + change question
          case '1vs1': howMany = 6; break;
          case 'practice': howMany = arraiesOfIds[0].length; break;
        }

        const fromEach = Math.min(1, howMany / arraiesOfIds.length);
        const result = [];
        for (const ids of arraiesOfIds) {
          result.push(...ids.slice(0, fromEach));
        }
        const left = howMany - result.length;
        result.push(...arraiesOfIds[0].slice(fromEach, fromEach + left));

        shuffle(result);

        return result;
      })).pipe(flatMap(ids => {
        const cleanIds = ids.map(o => o.id);
        return this.apollo.watchQuery<Quiz>({
          variables: {
            ids: cleanIds,
            inTest: (['test', 'finalTest'].indexOf(this.activity) > -1),
          },
          query: gql`
          query ProblemsByIds($ids: [Int]!, $inTest: Boolean) {
            problemsbyidsList(ids: $ids) {
              id
              subject {
                hierarchy
                kind
                test(ing: $inTest)
              }
              difficulty {
                id
              }
              question
              correct
              wrong2
              wrong3
              wrong4
            }
          }
          `,
        }).valueChanges;
      })).pipe(shareReplay(1));
  }

  ngOnDestroy() {
    this.sliderService.unregister();
    if (this.scoreSubscription) { this.scoreSubscription.unsubscribe(); }
    return this.querySubscription && this.querySubscription.unsubscribe();
  }

  currentProblemChanged(index) {
    setTimeout(() => {
      this.apollo.getClient().writeData({
        data: {
          ui: {
            id: 0,
            currentProblemId: this.problems[index].id,
            __typename: 'UI'
          }
        }
      });
    }, 0);
  }

  onReview(wasCorrect) {
    if (wasCorrect === undefined) {
      return;
    }
    this.rightAnswers.next(wasCorrect);
  }

  onAnswerChosen(answer: { id: number, correct: boolean }) {
    if (this.activity === 'million') {
      if (answer.correct) {
        of(0).pipe(delay(1500), map(() => {
          this.sliderService.nextSlide();
          this.millionService.advance();
        })).subscribe();
      } else {
        this.millionService.gameover();
      }
    }
    this.selected.emit(answer);
  }

  times(n: number) {
    if (!Number.isInteger(n)) { n = 5; }
    return new Array(n);
  }
}
