import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { HelpComponent } from './help/help.component';
import { UIRouter } from '@uirouter/angular';
import { ProfessionComponent } from './profession/profession.component';
import { Timer } from './header/menu/timer';
import { distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { SliderService } from './slider.service';

export const levels = [
  '100',
  '200',
  '300',
  '400',
  '500',
  '1,000',
  '2,500',
  '5,000',
  '10,000',
  '25,000',
  '50,000',
  '100,000',
  '200,000',
  '500,000',
  '1,000,000'
] as const;

@Injectable({
  providedIn: 'root'
})
export class MillionService {
  private MINUTES_PER_QUESTION = 1.5;
  private level: BehaviorSubject<number>;
  public readonly level$: Observable<number>;
  private hide: BehaviorSubject<boolean>;
  public readonly hide$: Observable<boolean>;
  private timer: Timer;
  public readonly timer$: Observable<string>;

  constructor(private dialog: MatDialog, private router: UIRouter, private sliderService: SliderService) {
    this.level = new BehaviorSubject(1);
    this.level$ = this.level.asObservable();
    this.hide = new BehaviorSubject(false);
    this.hide$ = this.hide.asObservable().pipe(distinctUntilChanged());
    this.timer = new Timer(this.MINUTES_PER_QUESTION);
    this.timer$ = this.timer.seconds$;

    this.level$.subscribe(() => {
      this.timer.reset();
    });

    this.timer.finished$.pipe(map(finished => {
      if (finished) { this.gameover(); }
    })).subscribe();
  }

  advance() {
    this.hide.next(false);
    this.level.next(this.level.getValue() + 1);
  }

  reset() {
    this.level.next(1);
    this.hide.next(false);
  }

  gameover() {
    this.sliderService.onlyReview();

    const score = levels[this.level.getValue() - 1];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.direction = 'rtl';
    dialogConfig.maxHeight = '90%';
    dialogConfig.data = {
      title: of('המשחק הסתיים'),
      html: `הצלחתם להגיע ל-${score} נקודות! בטח תצליחו יותר בפעם הבאה! `
    };
    const dialogRef = this.dialog.open(HelpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.hide.next(false);

      const p = this.router.stateService.params;
      // assert practise state
      const t = (p.topic || p.topics[0]) as { name: string, hierarchy: string };
      const profession = ProfessionComponent.profession(t.hierarchy);
      this.router.stateService.go('activity', profession);
    });
  }

  hideHalf() {
    this.hide.next(true);
  }

  addTime() {
    this.timer.add(this.MINUTES_PER_QUESTION);
  }

  changeQuestion() {
    this.sliderService.nextSlide();
    this.timer.reset();
  }
}
