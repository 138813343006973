import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import gql from 'graphql-tag';

export interface UI {
  id: number;
  currentProblemId: number;
  subjectList: any[];
  activity: 'practice' | 'test' | 'finalTest' | 'million' | '1vs1' | '_';
  activitySubjects: 'practice' | 'test' | 'finalTest' | 'million' | '1vs1' | '_';
  type: 'math' | 'text';
  score: number;
  __typename: 'UI';
}

class MutationFactory {
  static create(fragment: any) {
    return (_: any, variables: Partial<UI>, { cache, getCacheKey }) => {
      const id = getCacheKey({ __typename: 'UI', id: 0 });
      const ui = cache.readFragment({ fragment, id }) as UI;
      const data = { ...ui, ...variables };
      // data[attribute] = variables[attribute];
      cache.writeData({ id, data });
      return null;
    };
  }
}

const resolvers = {
  Mutation: {
    changeSubjects: MutationFactory.create(gql`
      fragment subjectList on UI {
        id
        subjectList {
          name
          hierarchy
        }
      }
    `),
    changeActivity: MutationFactory.create(gql`
      fragment activity on UI {
        id
        activity
      }
    `),
    changeActivitySubjects: MutationFactory.create(gql`
      fragment activitySubjects on UI {
        id
        activitySubjects
      }
    `),
    changeType: MutationFactory.create(gql`
      fragment type on UI {
        id
        type
      }
    `),
    changeScore: MutationFactory.create(gql`
      fragment score on UI {
        id
        score
      }
    `),
  }
};

export const UPDATE_ACTIVITY = gql`
  mutation ChangeActivity($input: string)  {
    changeActivity(activity: $input) @client
  }
`;

export const UPDATE_ACTIVITY_SUBJECTS = gql`
  mutation ChangeActivitySubjects($input: string)  {
    changeActivitySubjects(activitySubjects: $input) @client
  }
`;

export const UPDATE_TYPE = gql`
  mutation ChangeType($input: string)  {
    changeType(type: $input) @client
  }
`;

export const UPDATE_SUBJECTS = gql`
  mutation ChangeSubjects($input: [PartialSubjectInput])  {
    changeSubjects(subjectList: $input) @client
  }
`;

const typeDefs = gql`
  input PartialSubjectInput {
      name: String
      hierarchy: String
  }
`;

const authLink = setContext((_, { headers }) => {
  headers = headers || {};
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return {
    headers
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError.name === 'HttpErrorResponse') {
    type HttpError = Error & { status: number };
    const httpError = networkError as HttpError;
    if (httpError.status === 401) {
      localStorage.removeItem('token');
      window.location.assign('/#/login');
    }

    // console.log(`[Network error]: ${networkError}`);
  }
});

// const uri = 'https://4ksn2.sse.codesandbox.io/'; // <-- add the URL of the GraphQL server here
// const uri = 'http://localhost:5000/graphql';
const uri = '/graphql';

export function createApollo(httpLink: HttpLink) {
  const ui: UI = {
    id: 0,
    currentProblemId: 0,
    subjectList: [],
    activity: '_',
    activitySubjects: '_',
    type: 'math',
    score: 100,
    __typename: 'UI'
  };
  const cache = new InMemoryCache();
  cache.writeData({ data: { ui } });
  return {
    link: errorLink.concat(authLink.concat(httpLink.create({ uri }))),
    cache,
    resolvers
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
