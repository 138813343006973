import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Subject as SubjectModel } from './subject/subject.model';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { pluck, map, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'mea-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css']
})
export class SubjectsComponent implements OnInit {
  @HostBinding('class') cssClass = 'board__subjects';
  subjects$: any;
  activity: string;

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    const graphqlActivity = this.apollo.watchQuery<any>({
      query: gql`
        query CurrentActivitySubjects {
          ui @client {
            activitySubjects
          }
        }
      `
    }).valueChanges.pipe(pluck('data', 'ui', 'activitySubjects'));

    this.subjects$ = this.apollo.watchQuery<any>({
      query: gql`
        query CurrentSubjects {
          ui @client {
            subjectList {
              name
              hierarchy
            }
          }
        }
      `
    }).valueChanges.pipe(pluck('data', 'ui', 'subjectList')).
      pipe(withLatestFrom(graphqlActivity)).
      pipe(map(([subjects, activitySubjects]: [any, string]) => {
        if (!subjects) {
          return;
        }
        this.activity = activitySubjects;
        /*
        debugger;
        const TOTAL_SUBJECTS = 3;
        const emptySubject: SubjectModel = { hierarchy: '', name: '' };
        return subjects.concat(Array(TOTAL_SUBJECTS - subjects.length).fill(emptySubject));
        */
        return subjects;
      }));
  }

  profession(hierarchy) {
    const profession = hierarchy.substr(0, 2);

    const PROFESSIONS = {
      '03': { name: 'מתמטיקה', hierarchy: '03' },
      '05': { name: 'אנגלית', hierarchy: '05' },
      '01': { name: 'לשון', hierarchy: '01' },
      '06': { name: 'היסטוריה', hierarchy: '06' },
      '04': { name: 'תנך', hierarchy: '04' },
      '02': { name: 'אזרחות', hierarchy: '02' },
    };

    return PROFESSIONS[profession];
  }
}
