import { Component, Input, HostBinding } from '@angular/core';
import { Subject as SubjectModel } from './subject.model';
@Component({
  selector: 'mea-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent {
  @Input() isSubtopic?: boolean;

  @HostBinding('class') private get cssClass() {
    const cssClasses = ['board__subject'];
    if (this.isSubtopic) {
      cssClasses.push('board__subject--subtopic');
    } else {
      cssClasses.push(' board__subject--topic js_link');
    }
    return cssClasses.join(' ');
  }

  @Input() subject: SubjectModel;
}
