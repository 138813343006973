import { Component, OnInit, HostBinding, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, of, Subject, ReplaySubject, Subscription } from 'rxjs';
import { map, flatMap, filter, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Problem } from './header/menu/menu.component';
import { Subject as SubjectModel } from './header/subjects/subject/subject.model';
import { SliderService } from './slider.service';
import { MillionService } from './million.service';
import { UserService } from './user.service';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'student-client';
  @HostBinding('class') private class = 'playground';

  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Input() game?: '1vs1' | 'million' | undefined;
  @Input() type: 'math' | 'text' = 'math'; // TODO remove me for menu
  user: string;



  @HostBinding('class.playground--ingame') private get ingameCss() {
    return this.isActive_ && this.game !== undefined;
  }

  get hideHeader() {
    const toHide = ['/login', '/register'].indexOf(this.router.urlService.path()) > -1;
    return (toHide) ? 'none' : 'flex';
  }

  private _subscriptions: Subscription[] = [];
  loading$: Observable<boolean>;
  error$: Observable<readonly any[]>;
  quiz$: Observable<SubjectModel[]>;

  public problems: Problem[];
  public problems$: Observable<Problem[]>;
  subjects$: Observable<SubjectModel[]>;
  current = 0;
  length = 1;

  atStart$: Observable<boolean>;
  atEnd$: Observable<boolean>;
  isActive$: Observable<boolean>;
  isActive_: boolean;
  activity$: Observable<string>;
  topSubject$: Observable<{ name: string; hierarchy: string; }>;
  newActivity$: Observable<'practice' | 'test' | 'finalTest' | 'million' | '1vs1'>;

  showExit: Subject<boolean> = new ReplaySubject(1);
  public readonly showExit$ = this.showExit.asObservable().pipe(distinctUntilChanged());
  showReview: Subject<boolean> = new ReplaySubject(1);
  public readonly showReview$ = this.showReview.asObservable().pipe(distinctUntilChanged());
  showNav$: Observable<boolean>;

  constructor(
    private apollo: Apollo,
    public sliderService: SliderService,
    public millionService: MillionService,
    private userService: UserService,
    private router: UIRouter) {

  }

  ngOnInit() {
    window.location.assign('/#/');
    // debugger;
    this.atStart$ = this.sliderService.atStart;
    this.atEnd$ = this.sliderService.atEnd;
    this.isActive$ = this.sliderService.isActive;
    this._subscriptions.push(
      this.isActive$.subscribe(isActive => this.isActive_ = isActive)
    );
    this.user = this.userService.email;

    const graphqlActivity = this.apollo.watchQuery<any>({
      query: gql`
        query currentActivity {
          ui @client {
            activity
          }
    }`}).valueChanges.pipe(map(result => result.data.ui.activity));

    this.newActivity$ = this.sliderService.isActive.pipe(filter(isActive => isActive)).pipe(
      withLatestFrom(graphqlActivity),
      map(([_, activity]) => {
        return activity;
      })
    );

    this.showNav$ = this.newActivity$.pipe(distinctUntilChanged(), map(
      activity => (activity !== 'million' && activity !== '1vs1')
    ));


    this._subscriptions.push(
      this.sliderService.inReview.pipe(
        withLatestFrom(this.newActivity$),
        map(([inReview, activity]) => {
          // debugger;
          const inTest = ['test', 'finalTest'].indexOf(activity) > -1;
          this.showReview.next(inTest && !inReview);
          this.showExit.next(inReview || !inTest);
        })
      ).subscribe(),
      this.isActive$.pipe(filter((isActive) => !isActive), map(() => {
        this.game = undefined;
      })).subscribe()
    );

    this.activity$ = this.newActivity$.
      pipe(withLatestFrom(this.sliderService.inReview)).
      pipe(map(([activity, inReview]) => {
        const ACTIVITY_TO_HEBREW = {
          practice: 'תרגול',
          test: 'בוחן',
          finalTest: 'מבחן'
        };

        if (activity === 'million' || activity === '1vs1') {
          this.game = activity;
        } else {
          this.game = undefined;
        }

        const hebrew = (activity in ACTIVITY_TO_HEBREW) ?
          ACTIVITY_TO_HEBREW[activity] : 'פעילות';

        const end = inReview ? 'יציאה מה' : 'סיום ';

        return (end + hebrew);
      })); // .pipe(tap(x => { debugger; console.log('xxx: ' + x); } ));

    this.topSubject$ = this.isActive$.pipe(flatMap(isActive => {
      if (!isActive) { return of({ name: '', hierarchy: '' }); }
      return this.apollo.query<any>({
        query: gql`
        query currentSubjectList {
          ui @client {
            subjectList {
              name
              hierarchy
            }
          }
        }
      `
      }).pipe(map(result => {
        if (result.stale || !result.data.ui.subjectList || !result.data.ui.subjectList.length) {
          return { name: '', hierarchy: '' };
        }
        return this.extractTopSubject(result.data.ui.subjectList[0]);
      }));
    }));
  }

  extractTopSubject(subject: { name: string; hierarchy: string; }): { name: string; hierarchy: string; } {
    const HIERARCHY_TO_NAME = {
      '01': 'לשון',
      '02': 'אזרחות',
      '03': 'מתמטיקה',
      '04': 'תנ"ך',
      '05': 'אנגלית',
      '06': 'היסטוריה'
    };
    const hierarchy = subject.hierarchy.substr(0, '01'.length);
    const name = (hierarchy in HIERARCHY_TO_NAME) ? HIERARCHY_TO_NAME[hierarchy] : 'לא נמצא';
    return { name, hierarchy };
  }

  ngOnDestroy() {
    for (let subscription of this._subscriptions) {
      subscription.unsubscribe();
    }
  }
}
