import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { NgModule, Injector } from '@angular/core';

import { AppComponent } from './app.component';
import { AnswersSectionComponent } from './answers-section/answers-section.component';
import { QuestionSectionComponent } from './question-section/question-section.component';
import { HeaderComponent } from './header/header.component';
import { StudentComponent } from './header/student/student.component';
import { MenuItemComponent } from './header/menu/menu-item/menu-item.component';
import { MenuComponent } from './header/menu/menu.component';
import { SubjectComponent } from './header/subjects/subject/subject.component';
import { SubjectsComponent } from './header/subjects/subjects.component';
import { PlaygroundComponent } from './playground/playground.component';
import { HelpComponent } from './help/help.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AnswerComponent } from './answers-section/answer/answer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UIRouterModule, Ng2StateDeclaration, UIRouter, Transition } from '@uirouter/angular';
import { visualizer } from '@uirouter/visualizer';
import { ListViewModule, ListViewComponent } from '@syncfusion/ej2-angular-lists';
import { TreeViewModule, ContextMenuComponent } from '@syncfusion/ej2-angular-navigations';

import { HomeComponent } from './home/home.component';
import { ActivityComponent } from './activity/activity.component';
import { ProfessionComponent } from './profession/profession.component';
import { TopicComponent } from './topic/topic.component';
import { MillionComponent } from './million/million.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './register/register.component';
import { MillionareAppComponent } from './millionare-app/millionare-app.component';
import { PracticeAppComponent } from './practice-app/practice-app.component';
import { PaymentComponent } from './register/payment/payment.component';

export function resolver(trans) {
  const params = trans.params();
  return this.attribute ? params[this.attribute] : params;
}

export function professionFn(trans) {
  return trans.params().profession;
}
export function activityFn(trans) {
  return trans.params().activity;
}
export function topicFn(trans) {
  return trans.params().topic;
}
export function topicsFn(trans) {
  return trans.params().topics;
}
export function deeplinkFn(trans) {
  return trans.params().deeplink;
}
export function pathFn(trans) {
  return trans.params().path;
}
export function emptyFn(trans) {
  return trans.params();
}
/*export function mathFn() {
  return 'math';
}*/
export function typeFn(trans) {
  let t: string;
  if (trans.params().topic) {
    t = trans.params().topic.hierarchy;
  } else if (trans.params().topics) {
    t = trans.params().topics[0];
  }
  if (!t) { return 'text'; }
  const isMath = t.startsWith('03');
  return (isMath ? 'math' : 'text');
}


export const STATES: Ng2StateDeclaration[] = [
  {
    name: 'home', redirectTo: 'profession', component: HomeComponent,
    params: {
      type: null,
    },
    resolve: [
      { token: 'type', deps: [Transition], resolveFn: typeFn },
    ]
  },
  {
    parent: 'home', name: 'profession', url: '/', component: ProfessionComponent,
  },
  {
    parent: 'home', name: 'activity', url: '/', component: ActivityComponent,
    params: {
      name: null,
      hierarchy: null
    },
    resolve: [
      { token: 'profession', deps: [Transition], resolveFn: emptyFn }
    ]
  },
  {
    parent: 'home', name: 'topic', url: '/', component: TopicComponent,
    params: {
      profession: null,
      activity: null,
      deeplink: null,
    },
    resolve: [
      { token: 'profession', deps: [Transition], resolveFn: professionFn },
      { token: 'activity', deps: [Transition], resolveFn: activityFn },
      { token: 'deeplink', deps: [Transition], resolveFn: deeplinkFn },
    ]
  },
  {
    parent: 'home',
    name: 'practice', url: '/practice', component: PlaygroundComponent,
    params: {
      activity: null,
      topic: null,
      path: null,
      topics: null,
      type: null,
    },
    resolve: [
      { token: 'activity', deps: [Transition], resolveFn: activityFn },
      { token: 'topic', deps: [Transition], resolveFn: topicFn },
      { token: 'path', deps: [Transition], resolveFn: pathFn },
      { token: 'type', deps: [Transition], resolveFn: typeFn },
      { token: 'topics', deps: [Transition], resolveFn: topicsFn },
    ]
  },
  {
    name: 'practice2', url: '/practice2', component: PracticeAppComponent,
    params: {
      activity: null,
      topic: null,
      path: null,
      topics: null,
    },
    resolve: [
      { token: 'activity', deps: [Transition], resolveFn: activityFn },
      { token: 'topic', deps: [Transition], resolveFn: topicFn },
      { token: 'path', deps: [Transition], resolveFn: pathFn },
      { token: 'type', deps: [Transition], resolveFn: typeFn },
      { token: 'topics', deps: [Transition], resolveFn: topicsFn },
    ]
  },
  { name: 'notFound', url: '/404', component: PageNotFoundComponent },
  { name: 'login', url: '/login', component: LoginComponent },
  { name: 'register', url: '/register', component: RegisterComponent },
  { name: 'payment', url: '/payment', component: PaymentComponent }
];

/** UIRouter Config  */
export function uiRouterConfigFn(router: UIRouter, injector: Injector) {
  // If no URL matches, go to the `hello` state by default
  router.urlService.rules.otherwise({ state: 'hello' });

  // https://ui-router.github.io/guide/transitionhooks
  router.transitionService.onBefore({}, (transition) => {
    const to = transition.to().name;
    const isAuthed = localStorage.getItem('token');
    const anonymousAllowed = ['login', 'register', 'payment', 'notFound'].indexOf(to) > -1;
    if (!isAuthed && !anonymousAllowed) {
      return transition.router.stateService.target('login');
    }
    if (to === 'login' && isAuthed) {
      return transition.router.stateService.target('home');
    }
  });

  // Use ui-router-visualizer to show the states as a tree
  // and transitions as a timeline
  // visualizer(router);
}

@NgModule({
  declarations: [
    AppComponent,
    AnswerComponent,
    AnswersSectionComponent,
    QuestionSectionComponent,
    MenuItemComponent,
    MenuComponent,
    SubjectComponent,
    SubjectsComponent,
    StudentComponent,
    HeaderComponent,
    PlaygroundComponent,
    HelpComponent,
    PageNotFoundComponent,
    HomeComponent,
    ActivityComponent,
    ProfessionComponent,
    TopicComponent,
    MillionComponent,
    LoginComponent,
    RegisterComponent,
    PaymentComponent,
    MillionareAppComponent,
    PracticeAppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatInputModule,
    GraphQLModule,
    HttpClientModule,
    SwiperModule,
    BrowserAnimationsModule,

    ListViewModule,
    TreeViewModule,

    UIRouterModule.forRoot({
      states: STATES,
      useHash: true,
      otherwise: { state: 'notFound' },
      config: uiRouterConfigFn,
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [HelpComponent]
})
export class AppModule { }
