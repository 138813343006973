import { Component, OnInit, AfterContentInit, HostBinding, OnDestroy } from '@angular/core';
import { levels, MillionService } from '../million.service';
import { Observable, of } from 'rxjs';
import { SliderService } from '../slider.service';
import { delay, map } from 'rxjs/operators';

@Component({
  selector: 'mea-million',
  templateUrl: './million.component.html',
  styleUrls: ['./million.component.css']
})
export class MillionComponent implements OnInit, OnDestroy { // }, AfterContentInit {
  levels = levels.slice();
  active$: Observable<number>;
  timer$: Observable<string>;

  @HostBinding('class.board__game') private game = true;

  constructor(private millionService: MillionService, private sliderService: SliderService) {
    this.levels.reverse();
    this.active$ = this.millionService.level$;
    this.timer$ = this.millionService.timer$;
  }

  ngOnInit() {
    this.millionService.reset();
  }

  ngOnDestroy() {
    this.millionService.reset();
  }

  /*ngAfterContentInit() {
    of(true).pipe(delay(1000), map(() => { this.sliderService.resize(); })).subscribe();
  }*/
}
