import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnDestroy } from '@angular/core';
import { MillionService } from '../million.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'mea-answers-section',
  templateUrl: './answers-section.component.html',
  styleUrls: ['./answers-section.component.css']
})
export class AnswersSectionComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'board__section board__answers board__answers--grid';
  @Input() kind: 'image' | 'text';
  @Input() inTest: boolean;
  @Input() correct: string;
  @Input() wrong2: string;
  @Input() wrong3: string;
  @Input() wrong4: string;
  @Input() hierarchy: string;
  @Input() question: number;
  answers: string[];
  checked: boolean[] = [false, false, false, false];
  // @Input() correct: 0 | 1 | 2 | 3;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  correctAnswer = false;
  @Output() score: EventEmitter<boolean> = new EventEmitter();
  _inReview: boolean;
  hidden: number[] = [];

  private _subscriptions: Subscription[] = [];

  // @Input() inReview: boolean;

  constructor(private millionService: MillionService) { }

  ngOnInit() {
    this.answers = shuffle([this.correct, this.wrong2, this.wrong3, this.wrong4]);
    this._subscriptions.push(
      this.selected.subscribe((answer: { id: number, correct: boolean }) => {
      this.correctAnswer = answer.correct;
      })
    );

    const correctIndex = this.answers.findIndex(v => v === this.correct);
    this.hidden = [];
    const toHide = [0, 1, 2, 3].filter(v => v !== correctIndex).slice(0, 2); // last always shown
    this._subscriptions.push(
      this.millionService.hide$.subscribe(hide => {
        this.hidden = (hide) ? toHide : [];
      })
    );
  }

  @Input() set inReview(inReview: boolean) {
    this._inReview = inReview;
    if (inReview) {
      this.score.emit(this.correctAnswer);
    }
  }

  get inReview() {
    return this._inReview;
  }

  ngOnDestroy() {
    for (let subscription of this._subscriptions) {
      subscription.unsubscribe();
    }
  }
}


function shuffle<T>(array: T[]) {
  let currentIndex = array.length;
  let temporaryValue: T;
  let randomIndex: number;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
