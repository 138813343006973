import { Component, OnInit, HostBinding, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Observable, of, Subscription, from, identity, Subject, ReplaySubject } from 'rxjs';
import { map, shareReplay, pluck, delay, flatMap, tap, filter, withLatestFrom, distinct, distinctUntilChanged, take } from 'rxjs/operators';
import gql from 'graphql-tag';
import { Apollo, QueryRef } from 'apollo-angular';
import { ProblemData, OldProblem, QuizData, Quiz, Problem } from '../header/menu/menu.component';
import { Subject as SubjectModel } from '../header/subjects/subject/subject.model';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { SliderService } from '../slider.service';
import { MillionService } from '../million.service';
import { UserService } from '../user.service';
import { UIRouter } from '@uirouter/angular';

@Component({
  selector: 'mea-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  title = 'student-client';
  @HostBinding('class') private class = 'playground';

  @Output() selected: EventEmitter<any> = new EventEmitter();
  @Input() type: 'math' | 'text';
  user: string;
  inReview: boolean;

  /*@HostBinding('class.playground') private get loggedInCss() {
    return this.user !== undefined;
  }*/

  @HostBinding('class.playground--ingame') private get ingameCss() {
    return (this.activity === 'million' || this.activity === '1vs1');
  }

  loading$: Observable<boolean>;
  error$: Observable<readonly any[]>;
  quiz$: Observable<SubjectModel[]>;

  public problems: Problem[];
  public problems$: Observable<Problem[]>;
  subjects$: Observable<SubjectModel[]>;
  current = 0;
  length = 1;

  atStart$: Observable<boolean> = of(true);
  atEnd$: Observable<boolean> = of(false);
  isActive$: Observable<boolean>;
  isActive_: boolean;
  activity$: Observable<string>;
  activity: '_' | 'practice' | 'test' | 'finalTest' | 'million' | '1vs1' = '_';
  topSubject$: Observable<{ name: string; hierarchy: string; }>;
  newActivity$: Observable<'_' | 'practice' | 'test' | 'finalTest' | 'million' | '1vs1'>;
  type$: Observable<'math' | 'text'>;

  showExit: Subject<boolean> = new ReplaySubject(1);
  public readonly showExit$ = this.showExit.asObservable().pipe(distinctUntilChanged());
  showReview: Subject<boolean> = new ReplaySubject(1);
  public readonly showReview$ = this.showReview.asObservable().pipe(distinctUntilChanged());
  showNav$: Observable<boolean>;

  constructor(
    private apollo: Apollo,
    public sliderService: SliderService,
    public millionService: MillionService,
    private userService: UserService,
    private router: UIRouter) {

  }

  ngOnInit() {
    window.location.assign('/#/');
    this.atStart$ = this.sliderService.atStart;
    this.atEnd$ = this.sliderService.atEnd;
    this.isActive$ = this.sliderService.isActive;
    this.isActive$.subscribe(isActive => this.isActive_ = isActive);
    this.user = this.userService.email;
    this.inReview = false;

    const graphqlActivity = this.apollo.watchQuery<any>({
      query: gql`
        query currentActivity {
          ui @client {
            activity
          }
    }`}).valueChanges.pipe(map(result => result.data.ui.activity));

    this.type$ = this.apollo.watchQuery<any>({
      query: gql`
        query currentType {
          ui @client {
            type
          }
    }`}).valueChanges.pipe(map(result => result.data.ui.type));

    this.newActivity$ = graphqlActivity.pipe(tap(activity => {
      this.activity = activity;
      if (this.activity === '_') {
        this.inReview = false;
      }
      return activity;
    }));

    this.showNav$ = this.newActivity$.pipe(distinctUntilChanged(), map(
      activity => (activity !== 'million' && activity !== '1vs1')
    ));


    this.sliderService.inReview.pipe(
      withLatestFrom(this.newActivity$),
      map(([inReview, activity]) => {
        const inTest = ['test', 'finalTest'].indexOf(activity) > -1;
        this.showReview.next(inTest && !inReview);
        this.showExit.next(inReview || !inTest);
      })
    ).subscribe();

    this.activity$ = this.newActivity$.
      pipe(withLatestFrom(this.sliderService.inReview)).
      pipe(map(([activity, inReview]) => {
        const ACTIVITY_TO_HEBREW = {
          practice: 'תרגול',
          test: 'בוחן',
          finalTest: 'מבחן'
        };

        const hebrew = (activity in ACTIVITY_TO_HEBREW) ?
          ACTIVITY_TO_HEBREW[activity] : 'פעילות';

        const end = inReview ? 'יציאה מה' : 'סיום ';

        return (end + hebrew);
      }));

    this.topSubject$ = this.isActive$.pipe(flatMap(isActive => {
      if (!isActive) { return of({ name: '', hierarchy: '' }); }
      return this.apollo.query<any>({
        query: gql`
        query currentSubjectList {
          ui @client {
            subjectList {
              name
              hierarchy
            }
          }
        }
      `
      }).pipe(map(result => {
        if (result.stale || !result.data.ui.subjectList || !result.data.ui.subjectList.length) {
          return { name: '', hierarchy: '' };
        }
        return this.extractTopSubject(result.data.ui.subjectList[0]);
      }));
    }));
  }

  extractTopSubject(subject: { name: string; hierarchy: string; }): { name: string; hierarchy: string; } {
    const HIERARCHY_TO_NAME = {
      '01': 'לשון',
      '02': 'אזרחות',
      '03': 'מתמטיקה',
      '04': 'תנ"ך',
      '05': 'אנגלית',
      '06': 'היסטוריה'
    };
    const hierarchy = subject.hierarchy.substr(0, '01'.length);
    const name = (hierarchy in HIERARCHY_TO_NAME) ? HIERARCHY_TO_NAME[hierarchy] : 'לא נמצא';
    return { name, hierarchy };
  }

  ngOnDestroy() {
  }

  onFinish() {
    if (this.activity === 'million') {
      this.millionService.gameover();
      return;
    }

    const inTest = ['test', 'finalTest'].indexOf(this.activity) > -1;

    if (inTest && !this.inReview) {
      this.inReview = true;
      this.sliderService.goToReview();
    } else {
      this.inReview = false;
      const topSubject$: Observable<{ name: string; hierarchy: string; }> = this.apollo.query<any>({
        query: gql`
        query currentSubjectList {
          ui @client {
            subjectList {
              name
              hierarchy
            }
          }
        }
      `
      }).pipe(pluck('data', 'ui', 'subjectList'), map(subjectList => {
        return this.extractTopSubject(subjectList[0]);
      }));

      topSubject$.pipe(take(1), map(subject => {
        this.router.stateService.go('activity', subject);
      })).subscribe();
    }
  }

  finishText(activity, inReview) {
    const ACTIVITY_TO_HEBREW = {
      practice: 'תרגול',
      test: 'בוחן',
      finalTest: 'מבחן'
    };
    const hebrew = (activity in ACTIVITY_TO_HEBREW) ?
      ACTIVITY_TO_HEBREW[activity] : 'פעילות';
    const end = inReview ? 'יציאה מה' : 'סיום ';
    return (end + hebrew);
  }

  showNav(activity) {
    return ['practice', 'test', 'finalTest'].indexOf(activity) > -1;
  }
}

