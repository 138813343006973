import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { Subject as SubjectModel } from './subjects/subject/subject.model';

@Component({
  selector: 'mea-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') private cssClass = 'board__header';
  @Input() inReview: boolean;
  @Input() activity: '_' | 'practice' | 'test' | 'finalTest' | 'million' | '1vs1';
  @Input() type: 'math' | 'text';

  studentName = 'אליאב מוסאי';

  constructor() { }

  ngOnInit() {
  }

}
