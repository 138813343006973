import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { UPDATE_SUBJECTS, UPDATE_ACTIVITY } from '../graphql.module';

@Component({
  selector: 'mea-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit, OnDestroy {
  @Input() profession: {name: string, hierarchy: string};
  private _subscriptions : Subscription[] = [];

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this._subscriptions.push(
        this.apollo.mutate({
        mutation: UPDATE_SUBJECTS,
        variables: {
          input: [
            { name: this.profession.name, hierarchy: this.profession.hierarchy, __typename: 'PartialSubjectInput' },
            { name: '', hierarchy: '', __typename: 'PartialSubjectInput' },
            { name: 'בחרו פעילות', hierarchy: '', __typename: 'PartialSubjectInput' },
          ]
        }
      }).subscribe(),
      this.apollo.mutate({
        mutation: UPDATE_ACTIVITY,
        variables: {
          input: '_'
        }
      }).subscribe()
    );
  }

  ngOnDestroy() {
    for (let subscription of this._subscriptions) {
      subscription.unsubscribe();
    }
  }

}
